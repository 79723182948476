<template>
  <div>
    <v-simple-table
      fixed-header
      height="calc(100vh - 205px)"
      class="table-padding-2-no-top"
    >
      <template v-slot:default>
        <thead class="v-data-table-header">
          <tr>
            <th role="columnheader" style="width: 130px">
              <InputFilter
                :label="$t('labels.warehouse_1')"
                :placeholder="$t('labels.warehouse_1')"
                name="code_name"
                sort-name="code_name"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader">
              <InputFilter
                :label="$t('labels.hotline')"
                :placeholder="$t('labels.hotline')"
                name="hotline"
                sort-name="hotline"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader" style="width: 130px">
              <SelectCity
                class="c-input-xs input-filter"
                name="id_city"
                :label="$t('labels.city')"
                @onChange="onFilterChange"
              />
            </th>
            <th role="columnheader" style="width: 130px">
              <SelectCounty
                class="c-input-xs input-filter"
                name="id_county"
                :id-city="filters.id_city"
                :label="$t('labels.district')"
                @onChange="onFilterChange"
              />
            </th>
            <th role="columnheader" style="width: 130px">
              <SelectWard
                class="c-input-xs input-filter"
                name="id_ward"
                :id-county="filters.id_county"
                :id-city="filters.id_city"
                :label="$t('labels.ward')"
                @onChange="onFilterChange"
              />
            </th>
            <th role="columnheader">
              <InputFilter
                :label="$t('labels.address')"
                :placeholder="$t('labels.address')"
                name="address"
                sort-name="address"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader">
              <InputFilter
                :label="$t('labels.latitude')"
                :placeholder="$t('labels.latitude')"
                name="latitude"
                sort-name="latitude"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader">
              <InputFilter
                :label="$t('labels.longitude')"
                :placeholder="$t('labels.longitude')"
                name="longitude"
                sort-name="longitude"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader" style="width: 130px">
              <SelectFilter
                :options="statusOptions"
                :label="$t('labels.status')"
                :placeholder="$t('labels.status')"
                name="active"
                sort-name="active"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader" class="text-center"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in items" :key="`w_${item.id}`" class="text-center">
            <td>{{ item.code_name }}</td>
            <td>{{ item.hotline }}</td>
            <td class="text-capitalize">{{ item.city_name }}</td>
            <td class="text-capitalize">{{ item.county_name }}</td>
            <td class="text-capitalize">{{ item.ward_name }}</td>
            <td>{{ item.address }}</td>
            <td>{{ item.latitude }}</td>
            <td>{{ item.longitude }}</td>
            <td :class="item.status_color">{{ item.status_text }}</td>
            <td>
              <v-btn
                v-if="checkPermission(['goods_transfer', 'pos_goods_transfer'])"
                outlined
                x-small
                color="warning"
                @click="showConfigDialog(item)"
                >{{ $t("labels.config") }}</v-btn
              >
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <div class="pt-3">
      <v-pagination
        v-model="page"
        :length="totalPage"
        :total-visible="5"
      ></v-pagination>
    </div>

    <v-dialog v-model="configDialog" persistent max-width="960px">
      <WarehouseConfig
        v-if="configDialog"
        :item="updatingItem"
        @close="hideConfigDialog"
      />
    </v-dialog>
  </div>
</template>

<script>
import { httpClient } from "@/libs/http";
import { debounce } from "lodash/function";
import { STATUS_OPTIONS } from "@/libs/const";

export default {
  name: "Warehouse",
  components: {
    SelectFilter: () => import("@/components/table/SelectFilter"),
    InputFilter: () => import("@/components/table/InputFilter"),
    SelectCity: () => import("@/components/common/SelectCity"),
    SelectCounty: () => import("@/components/common/SelectCounty"),
    SelectWard: () => import("@/components/common/SelectWard"),
    WarehouseConfig: () => import("@/components/account/WarehouseConfig"),
  },
  data: () => ({
    page: 1,
    totalPage: 1,
    totalItem: 0,
    totalActive: 0,
    items: [],
    filters: {},
    warehouse: {},
    isLoading: false,
    statusOptions: [...STATUS_OPTIONS],
    updatingItem: {},
    configDialog: false,
  }),
  watch: {
    filters: {
      handler() {
        this.page = 1;
        this.getList();
      },
      deep: true,
    },
  },
  mounted() {
    this.getList();
  },
  methods: {
    onFilterChange(filter) {
      this.filters = { ...this.filters, [filter.name]: filter.value };
    },
    onSortChange(sort) {
      this.filters = { ...this.filters, ...sort };
    },
    getStatusText(val) {
      return this.statusOptions.find((p) => p.value === val);
    },
    getList: debounce(function () {
      httpClient
        .post("/warehouse-list", { ...this.filters, page: this.page })
        .then(({ data }) => {
          this.totalPage = data.totalPage;
          this.items = [...data.rows].map((r) => {
            const status = this.getStatusText(r.active);
            r.status_text = status.text;
            r.status_color = status.color;

            return r;
          });
        });
    }, 500),
    showConfigDialog(item) {
      this.updatingItem = { ...item };
      this.configDialog = true;
    },
    hideConfigDialog() {
      this.updatingItem = {};
      this.configDialog = false;
    },
  },
};
</script>
